import imgixAssetsUrl from './imgixAssetsUrl'

export default (fileName: string, options: { w?: number; h?: number } = {}) => {
  // 解像度1, 2倍だとちょっと汚いので2, 4倍にしている
  const options2x: { w?: number; h?: number } = {}
  const options4x: { w?: number; h?: number } = {}
  if (options.w) {
    options2x.w = options.w * 2
    options4x.w = options.w * 4
  }
  if (options.h) {
    options2x.h = options.h * 2
    options4x.h = options.h * 4
  }
  return `${imgixAssetsUrl(fileName, options2x)} 1x, ${imgixAssetsUrl(
    fileName,
    options4x
  )} 2x`
}
